<template>
  <b-card>

    <!-- media -->
    <!--    <b-media no-body>-->
    <!--      <b-media-aside>-->
    <!--        <b-link>-->
    <!--          <b-img-->
    <!--            ref="previewEl"-->
    <!--            rounded-->
    <!--            :src="optionsLocal.avatar"-->
    <!--            height="80"-->
    <!--          />-->
    <!--        </b-link>-->
    <!--        &lt;!&ndash;/ avatar &ndash;&gt;-->
    <!--      </b-media-aside>-->

    <!--      <b-media-body class="mt-75 ml-75">-->
    <!--        &lt;!&ndash; upload button &ndash;&gt;-->
    <!--        <b-button-->
    <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--          variant="primary"-->
    <!--          size="sm"-->
    <!--          class="mb-75 mr-75"-->
    <!--          @click="$refs.refInputEl.$el.click()"-->
    <!--        >-->
    <!--          Upload-->
    <!--        </b-button>-->
    <!--        <b-form-file-->
    <!--          ref="refInputEl"-->
    <!--          v-model="profileFile"-->
    <!--          accept=".jpg, .png, .gif"-->
    <!--          :hidden="true"-->
    <!--          plain-->
    <!--          @input="inputImageRenderer"-->
    <!--        />-->
    <!--        &lt;!&ndash;/ upload button &ndash;&gt;-->

    <!--        &lt;!&ndash; reset &ndash;&gt;-->
    <!--        <b-button-->
    <!--          v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
    <!--          variant="outline-secondary"-->
    <!--          size="sm"-->
    <!--          class="mb-75 mr-75"-->
    <!--        >-->
    <!--          Reset-->
    <!--        </b-button>-->
    <!--        &lt;!&ndash;/ reset &ndash;&gt;-->
    <!--        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>-->
    <!--      </b-media-body>-->
    <!--    </b-media>-->
    <!--/ media -->

    <div class="font-medium-3">
      Account Details
    </div>
    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col
          sm="6"
          class="border-right"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="user-username">Full Name</label>
                <small class="text-danger font-weight-bold"> *</small>
                <b-form-input
                  id="user-username"
                  v-model="selfData.name"
                  placeholder="John Doe"
                  name="name"
                  @change="checkFormChanges()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label for="user-e-mail">Email</label>
                <small class="text-danger font-weight-bold"> *</small>
                <b-form-input
                  id="user-e-mail"
                  v-model="selfData.email"
                  name="email"
                  placeholder="john@example.com"
                  @change="checkFormChanges()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label for="user-phone">Phone</label>
                <small class="text-danger font-weight-bold"> *</small>
                <b-form-input
                  id="user-phone"
                  v-model="selfData.phone"
                  name="phone"
                  placeholder="08123456789"
                  @change="checkFormChanges()"
                />
              </b-form-group>
            </b-col>

            <h5 class="pl-1 mt-2">
              Business Details
            </h5>
            <b-col cols="12">
              <b-form-group>
                <label for="user-company_name">Company Name</label>
                <b-form-input
                  id="user-company_name"
                  v-model="selfData.company.name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label for="user-industry_name">Industry</label>
                <b-form-input
                  id="user-industry_name"
                  v-model="selfData.industry_name"
                  input-id="user-industry_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="false"
              cols="12"
            >
              <b-form-group
                label="Birth Date"
                label-for="user-birthdate"
              >
                <flat-pickr
                  id="user-birthdate"
                  v-model="selfData.birthdate"
                  class="form-control"
                  name="birthdate"
                  :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                  placeholder="YYYY-MM-DD"
                  @change="checkFormChanges()"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="false"
              cols="12"
            >
              <b-form-group
                label="Address"
                label-for="address"
              >
                <b-form-textarea
                  id="address"
                  v-model="selfData.address"
                  trim
                  placeholder="Current address"
                  rows="2"
                  @change="checkFormChanges()"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="false"
              cols="12"
            >
              <b-form-group>
                <label for="user-company_id">Company</label>
                <small class="text-danger font-weight-bold"> *</small>
                <v-select
                  v-model="selfData.company_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companyUsers"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-company_id"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="false"
              cols="12"
            >
              <b-form-group>
                <label for="user-company_name">Company Name</label>
                <small class="text-danger font-weight-bold"> *</small>
                <b-form-input
                  id="user-company_name"
                  v-model="selfData.company_name"
                  autofocus
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="selfData.role_id === roleOptions.find(o => o.code === 'su').value"
              cols="12"
              class="mb-2"
            >
              <span class="font-small-3">If you wish to change your company name or industry, you can contact us: <a href="mailto:support@tracebit.net">support@tracebit.net</a></span>
            </b-col>
            <b-col
              v-if="false"
              cols="12"
            >
              <b-form-group>
                <label for="user-industry_name">Industry Name</label>
                <small class="text-danger font-weight-bold"> *</small>
                <v-select
                  v-model="selfData.industry_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="industryNames"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-industry_name"
                />
              </b-form-group>
            </b-col>

            <h5 class="pl-1 mt-1">
              Security
            </h5>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="selfData.change_password"
                :value="true"
              >
                Change Password
              </b-form-checkbox>
              <b-form-group
                v-if="selfData.change_password"
                class="mt-1"
                label-for="new_password"
              >
                <!-- New Form -->
                <b-form-group>
                  <label for="current_password">Current Password</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="current_password"
                      v-model="selfData.current_password"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <label for="new_password">New Password</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="new_password"
                      v-model="selfData.new_password"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <label for="new_password_confirmation">Repeat New Password</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="new_password_confirmation"
                      v-model="selfData.new_password_confirmation"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <!-- Old Form -->
                <!-- <b-form-input
                  id="new_password"
                  v-model="selfData.new_password"
                  type="password"
                  @change="checkFormChanges()"
                /> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6">
          <div>
            Receive alerts in your email when your active Landing Page receive new submission.
          </div>
          <div class="mt-2">
            <b-form-checkbox
              v-model="selfData.form_submission_alert"
              :checked="selfData.form_submission_alert"
              name="form-submission-alert"
              switch
              inline
              @change="checkFormChanges()"
            >
              Form Submission Alert
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="disableBtn"
            class="mt-2 mr-1"
          >
            Update
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BRow, BCol, BCard, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { diff } from 'deep-diff'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import industryNames from '@/@fake-db/data/other/industry_name_options'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    selfData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.selfData)),
      profileFile: null,
      disableBtn: false,
      oldValueForm: null,
      roleOptions: [],
      companyUsers: [],
      industryNames,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // hasChanged() {
    //   return Object.keys(this.oldValueForm).some(field => { return this.oldValueForm[field] !== this.selfData[field] })
    // },
  },
  watch: {
    // selfData(newVal, oldVal) {
    //   console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    // },
  },
  mounted() {
    this.oldValueForm = this.selfData
    this.getRoles()
    this.getCompanies()
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.selfData))
    },
    getRoles() {
      store.dispatch('app-user/getRoles')
        .then(response => {
          this.roleOptions = response.data.data
        })
    },
    getCompanies() {
      store.dispatch('app-user/getCompanyUsers')
        .then(response => {
          this.companyUsers = response.data.data
          this.companyUsers.push({
            value: 0,
            label: 'Other',
          })
        })
    },
    checkFormChanges() {
      if (diff(this.oldValueForm, this.selfData)) {
        if (this.oldValueForm !== null) {
          this.disableBtn = false
        }
      }
    },
    onSubmit() {
      this.disableBtn = true
      store.dispatch('app-user/updateUser', {
        id: this.selfData.id,
        data: this.selfData,
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Successfull!',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.disableBtn = false
        })
        .catch(error => {
          if (error.response !== undefined){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${error.response.status} Error has occured`,
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.disableBtn = false
          }
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
