<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">Account</span>
      </template>

      <user-setting-account
        v-if="options.general"
        :general-data="options.general"
        :self-data="currentUser"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab v-if="currentUser.role.code === 'sa' || currentUser.role.code === 'su'">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        <span class="font-weight-bold">Users</span>
      </template>

      <user-list :current-user="currentUser" />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>

import { BTabs, BTab } from 'bootstrap-vue'
import store from '@/store'
import UserSettingAccount from './UserSettingAccount.vue'
import UserList from './users-list/UsersList.vue'

export default {
  components: {
    BTabs,
    BTab,
    UserSettingAccount,
    UserList,
  },
  data() {
    return {
      options: [],
      currentUser: {
        role: {
          code: '',
        },
      },
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  created(){
    this.fetchSelfData()
  },
  methods: {
    async fetchSelfData() {
      await store.dispatch('app-user/getSelf')
        .then(response => {
          this.currentUser = response.data
        })
    },
  },
}
</script>
